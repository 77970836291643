import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useClient } from 'urql';

import getLandingSearchAvailabilityQuery from './getLandingSearchAvailability.gql';
import { useUserPreferences } from '@Contexts/UserPreferencesContext/UserPreferencesContext';
import { mergeSearchParams } from '@Core/helpers/url';
import { readOrFetchQuery } from '@Core/readOrFetchQuery';
import { SearchSelectionStore } from '@Stores/SearchSelectionStore';
import { getSearchSelectionFromUrl } from '@Stores/SearchSelectionStore/getSearchSelectionFromUrl';
import { useStoreContext } from '@Stores/StoreContext';
import { trackSearchChanges } from '@Tracking';

type StoreSubscription = () => void;

interface Props {
  storeSelectionInCookie?: boolean;
}

export const LandingSearchAvailabilityStoreUpdater: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  storeSelectionInCookie = false,
}) => {
  const initialised = useRef(false);
  const subscriptions = useRef<StoreSubscription[]>();
  const urqlClient = useClient();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const { searchAvailability, searchSelection } = useStoreContext();
  const { savedSearchSelection } = useUserPreferences();

  const fetchAndUpdateAvailability = useCallback(({ isInitial = false }) => {
    const searchSelectionState = searchSelection.getState();

    searchAvailability.setState({ isAvailabilityFetching: true });

    // update the url (on client only)
    if (typeof window !== 'undefined' && !isInitial) {
      const newSearchParams = mergeSearchParams(
        history.location.search,
        searchSelectionState.toUrl('landingseo'),
        ['lhx'],
      );
      history.replace(`${pathname}?${newSearchParams}`);
    }

    const queryVariables = {
      searchSelection: {
        boardBasis: searchSelectionState.boardBasis,
        cancellationPolicy: searchSelectionState.cancellationPolicy,
        date: searchSelectionState.date,
        departureAirports: searchSelectionState.departureAirports,
        flexibility: searchSelectionState.flexibility,
        nights: searchSelectionState.nights,
        rooms: searchSelectionState.rooms,
        filters: searchSelectionState.filters,
        source: searchSelectionState.source,
        destinationIds: searchSelectionState.destinationIds,
      },
    };

    return readOrFetchQuery(
      urqlClient,
      getLandingSearchAvailabilityQuery,
      queryVariables,
      ({ data }) => {
        const availability = data?.Search.availability;

        searchAvailability.setState({
          isAvailabilityFetching: false,
          calendar: availability?.calendar || {},
          departureAirports: availability?.departureAirports || [],
          nights: availability?.nights || [],
          destinations: availability?.destinations || [],
        });

        trackSearchChanges(searchSelection.getState(), searchAvailability.getState());
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 1. Initialise SearchSelection store from URL params
  if (!initialised.current) {
    initialised.current = true;
    searchSelection.destroy();
    searchSelection.setState(getSearchSelectionFromUrl(search) as SearchSelectionStore);

    const initialFetch = fetchAndUpdateAvailability({
      isInitial: true,
    });

    // The initial fetch returns a promise in SSR prepass, which we need to throw for Suspense
    if (initialFetch instanceof Promise && typeof window === 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw initialFetch;
    }
  }

  // 2. Subscribe search selection changes to update availability
  if (!subscriptions.current) {
    subscriptions.current = [
      searchSelection.subscribe(() =>
        fetchAndUpdateAvailability({
          isInitial: false,
        }),
      ),
      storeSelectionInCookie &&
        searchSelection.subscribe((state) => {
          savedSearchSelection.set(state.toCookie());
        }),
    ].filter(Boolean) as StoreSubscription[];
  }

  // 3. Unsubscribe when component unmounts
  useEffect(
    () => () => {
      if (subscriptions.current) {
        subscriptions.current.forEach((teardown) => teardown());
      }
    },
    [],
  );

  return <Fragment>{children}</Fragment>;
};
